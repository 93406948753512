import { formatAsInt, formatTwoDecimals } from '../../../../utils/numberFormatter';

import React from 'react';
import { Tooltip } from '@mui/material';

const hoverStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TABLE_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const TABLE_COLUMNS = [
  {
    field: 'countryName',
    headerName: 'Country',
    width: 200,
    filterable: false,
    renderCell: (cell) => cell?.row?.country?.displayName,
  },
  { field: 'assetName', headerName: 'Asset/Block Name', width: 200, filterable: false },
  { field: 'shoreStatus', headerName: 'Shore Status', width: 200, filterable: false },
  {
    field: 'dealType',
    headerName: 'Deal Type',
    width: 200,
    filterable: false,
    renderCell: (cell) => cell.row?.maCompanyTransaction?.maDeal?.dealType,
  },
  {
    field: 'effectiveDate',
    headerName: 'Effective Date',
    type: 'date',
    width: 200,
    filterable: false,
    renderCell: (cell) => {
      const value = cell?.row?.maCompanyTransaction?.maDeal?.effectiveDate;
      return (
        <span>
          {new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          })}
        </span>
      );
    },
  },
  {
    field: 'buyerCompanyName',
    headerName: 'Buyer',
    width: 200,
    filterable: false,
    renderCell: (cell) => cell.row?.maCompanyTransaction?.buyerCompanyName,
  },
  {
    field: 'sellerCompanyName',
    headerName: 'Seller',
    width: 200,
    filterable: false,
    renderCell: (cell) => cell.row?.maCompanyTransaction?.sellerCompanyName,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 400,
    filterable: false,
    renderCell: (cell) => {
      const value = cell.row?.maCompanyTransaction?.maDeal?.comment;
      return (
        <Tooltip title={value}>
          <span style={hoverStyles}>{value}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'regionName',
    headerName: 'Region',
    width: 200,
    filterable: false,
    renderCell: (cell) => cell.row?.country?.region?.displayName,
  },
  {
    field: 'workingInterest',
    headerName: 'Stake Transacted (%)',
    width: 200,
    filterable: false,
  },
  {
    field: 'considerationMmusd',
    headerName: 'Base Consideration (US$MM)',
    width: 200,
    filterable: false,
    renderCell: (cell) =>
      formatAsInt({ value: cell.row?.maCompanyTransaction?.maDeal?.considerationMmusd }),
  },
  {
    field: 'weaWorkingInterestValuation',
    headerName: 'Transacted Valuation (US$MM)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaReservesAcquisitionCost',
    headerName: 'Reserves Acquisition Cost ($/boe)',
    width: 200,
    filterable: false,
    renderCell: formatTwoDecimals,
  },
  {
    field: 'weaPriceFlowingBarrel',
    headerName: 'Price per Flowing Barrel ($/boe/d)',
    width: 200,
    filterable: false,
    renderCell: formatTwoDecimals,
  },
  {
    field: 'weaWorkingInterestReserves',
    headerName: 'Transacted Reserves (MMboe)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaWorkingInterestLiquidsReserves',
    headerName: 'Transacted Oil/Condensate Reserves (MMbbl)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaWorkingInterestGasReserves',
    headerName: 'Transacted Gas Reserves (Bcf)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaWorkingInterestProduction',
    headerName: 'Transacted Production (boe/d)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaWorkingInterestLiquidsProduction',
    headerName: 'Transacted Oil/Condensate Production (bbl/d)',
    width: 200,
    filterable: false,
    renderCell: formatTwoDecimals,
  },
  {
    field: 'weaWorkingInterestGasProduction',
    headerName: 'Transacted Gas Production (MMcf/d)',
    width: 200,
    filterable: false,
    renderCell: formatAsInt,
  },
  {
    field: 'weaRemainingEmissionsIntensity',
    headerName: 'Remaining Emissions Intensity (kgCO2e/boe)',
    width: 200,
    filterable: false,
    renderCell: formatTwoDecimals,
  },
  {
    field: 'weaCurrentEmissionsIntensity',
    headerName: 'Current Emissions Intensity (kgCO2e/boe)',
    width: 200,
    filterable: false,
    renderCell: formatTwoDecimals,
  },
];
