import { ANALYTICS_EVENTS, ANALYTICS_EVENT_ACTIONS } from '../../utils/constants';
import {
  DEVELOPMENT_STATUS_QUERY,
  RESOURCE_THEME_QUERY,
  SHORE_STATUS_QUERY,
} from '../Filters/operations';
import { FilterBarWrapper, FilterSetsProvider, useFilterSetsContext } from '../FilterBarWrapper';
import { FilterKeys, Portfolios } from '../Filters';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { analytics, defaultDimensions, dimensions } from '../../utils/analytics';

import ConsolidateAssetsButton from '../Modals/ConsolidateAssets/button';
import Driver from 'driver.js';
import { LoadPortfolio } from '../Modals/UserPortfolios/components/LoadPortfolio';
import { PORTFOLIO_CONTEXT } from '../../pages/PortfolioAnalysis/constants';
import { PageActions } from '../PageActions';
import { SavePortfolio } from '../Modals/UserPortfolios/components/SavePortfolio';
import { SharePortfolio } from '../Modals/UserPortfolios/components/SharePortfolio';
import SnackbarContext from '../../contexts/SnackbarContext';
import WalkthroughContext from '../../contexts/WalkthroughContext';
import { getFulltextResultParameters } from '../../utils/globalSearch';
import { getUserId } from '../../utils/auth';
import makeStyles from '@mui/styles/makeStyles';
import { portfolioIsEmpty } from '../Filters/utils';
import { useConsolidatedAssetsController } from '../Modals/ConsolidateAssets/ConsolidatedAssets.controller';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    ...theme.sizes.fullPage,
    flexDirection: 'column',
  },
  filterItem: {
    width: '25%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  filterBar: {
    gap: '0.5rem',
    [theme.breakpoints.down('md')]: {
      gap: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
    },
  },
}));

export const defaultPortfolio = {
  filters: {
    [FilterKeys.assets]: [],
    [FilterKeys.countries]: [],
    [FilterKeys.companies]: [],
    [FilterKeys.basins]: [],
    [FilterKeys.assetUnits]: [],
    [FilterKeys.resourceThemes]: [],
    [FilterKeys.shoreStatuses]: [],
    [FilterKeys.developmentStatuses]: [],
    [FilterKeys.npvMin]: '',
    [FilterKeys.npvMax]: '',
    [FilterKeys.reservesMin]: '',
    [FilterKeys.reservesMax]: '',
    [FilterKeys.productionMin]: '',
    [FilterKeys.productionMax]: '',
    [FilterKeys.operator]: false,
    [FilterKeys.opportunities]: false,
    [FilterKeys.includeHistoricalParticipants]: false,
  },
  name: 'Portfolio',
};

const getPortfolioFromSearchParameters = (searchParams) => {
  const portfolio = JSON.parse(JSON.stringify(defaultPortfolio));
  switch (searchParams.entity) {
    case 'ASSET':
      portfolio.filters[FilterKeys.assets] = [
        {
          __typename: 'Asset',
          legacyId: searchParams.legacyId,
          name: searchParams.name,
          displayName: searchParams.name,
          country: {
            __typename: 'Country',
            isoCode: searchParams.isoCode,
          },
        },
      ];
      break;
    case 'COUNTRY':
      portfolio.filters[FilterKeys.countries] = [
        {
          __typename: 'Country',
          displayName: searchParams.name,
          name: searchParams.name,
          isoCode: searchParams.isoCode,
          geom: searchParams.geom,
          region: {},
          isChosen: true,
        },
      ];
      break;
    case 'COMPANY':
      portfolio.filters[FilterKeys.companies] = [
        {
          __typename: 'Company',
          name: searchParams.name,
        },
      ];
      break;
  }
  return portfolio;
};

const getDefaultPortfolio = (context) => {
  const searchParams = getFulltextResultParameters();
  if (searchParams?.action) {
    const portfolio = getPortfolioFromSearchParameters(searchParams);
    return [{ ...portfolio }];
  }

  const portfolios = JSON.parse(sessionStorage.getItem(context));
  try {
    if (
      portfolios &&
      portfolios.every((portfolio) =>
        Object.keys(defaultPortfolio.filters).every((filter) =>
          Object.keys(portfolio.filters).includes(filter),
        ),
      )
    ) {
      return portfolios;
    } else {
      return [{ ...defaultPortfolio }];
    }
  } catch {
    return [{ ...defaultPortfolio }];
  }
};

const PortfolioFilters = ({
  portfolios,
  applyBasicFilters,
  basicFilterTypes,
  context,
  googleAnalyticsTag,
  addPortfolio,
  setPortfolios,
  defaultFilterTypes,
  clearStagingPortfolio,
  stagingPortfolios,
  deletePortfolio,
  filtersModalOpen,
  openPortfoliosModal,
  developmentStatusData,
  resourceThemeData,
  shoreStatusData,
  open,
  setOpen,
  clearPortfolios,
  loadPortfolio,
}) => {
  const [discountRate, setDiscountRate] = useState(10);
  const [cashFlowsModalOpen, setCashFlowsModalOpen] = useState(false);

  const classes = useStyles();
  const { currentFilterSetIndex } = useFilterSetsContext();

  const consolidatedAssetsData = useConsolidatedAssetsController({
    portfolios,
  });

  const openCashFlowsModal = useCallback(() => {
    setCashFlowsModalOpen(true);
  }, []);

  return (
    <FilterBarWrapper
      filters={portfolios[currentFilterSetIndex].filters}
      applyFilters={(filters) => applyBasicFilters(filters, currentFilterSetIndex)}
      basicFilterTypes={basicFilterTypes}
      context={context}
      googleAnalyticsTag={googleAnalyticsTag}
    >
      <Portfolios
        addPortfolio={addPortfolio}
        apply={setPortfolios}
        basicFilterTypes={defaultFilterTypes}
        clearPortfolios={clearStagingPortfolio}
        context={context}
        defaultPortfolios={stagingPortfolios}
        deletePortfolio={deletePortfolio}
        googleAnalyticsTag={googleAnalyticsTag}
        isModalOpen={filtersModalOpen}
        openModalCallback={openPortfoliosModal}
        checkListItems={{
          developmentStatuses: developmentStatusData ? developmentStatusData.developmentStatus : [],
          resourceThemes: resourceThemeData ? resourceThemeData.resourceType : [],
          shoreStatuses: shoreStatusData ? shoreStatusData.shoreStatus : [],
        }}
        open={open}
        setOpen={setOpen}
      />

      <PageActions
        onOpenFilters={() => {
          setOpen(true);
          openPortfoliosModal();
        }}
        onClearFilters={clearPortfolios}
        presets={{
          filters: portfolios,
          setFilters: loadPortfolio,
          label: 'Portfolio',
          SaveComponent: (props) => <SavePortfolio {...props} />,
          LoadComponent: (props) => <LoadPortfolio {...props} />,
          ShareComponent: (props) => <SharePortfolio {...props} />,
          includeShare: true,
          context,
        }}
        mainActionLabel="Build Portfolio"
        mainAction={
          context === PORTFOLIO_CONTEXT
            ? {
                tooltip: consolidatedAssetsData.tooltipMessage,
                label: 'Cash Flow',
                onClick: () => consolidatedAssetsData.disabled || openCashFlowsModal(),
                disabled: consolidatedAssetsData.disabled,
              }
            : undefined
        }
        className={classes.filterBar}
      />
      {context === PORTFOLIO_CONTEXT ? (
        <ConsolidateAssetsButton
          consolidatedAssetsData={consolidatedAssetsData}
          companyIds={portfolios.map((portfolio) => portfolio.filters.companies.map((c) => c.id))}
          assetUnits={portfolios.map((portfolio) => portfolio.filters.assetUnits)}
          open={cashFlowsModalOpen}
          setOpen={setCashFlowsModalOpen}
          discountRate={discountRate}
          setDiscountRate={setDiscountRate}
          consolidateAssetsProps={{
            companyIds: portfolios.map((portfolio) => portfolio.filters.companies.map((c) => c.id)),
            assetUnits: portfolios.map((portfolio) => portfolio.filters.assetUnits),
          }}
        />
      ) : null}
    </FilterBarWrapper>
  );
};

export default ({
  context,
  googleAnalyticsTag,
  GraphingComponent,
  basicFilterTypes,
  defaultFilterTypes,
  walkthroughDriver = () => {},
}) => {
  const classes = useStyles();
  const { setSnack } = useContext(SnackbarContext);
  const { walkthrough, setWalkthrough, setSeenNewFeature } = useContext(WalkthroughContext);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [portfolios, setPortfolios] = useState([...getDefaultPortfolio(context)]);
  const [stagingPortfolios, setStagingPortfolios] = useState([...portfolios]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (walkthrough) {
      if (filtersModalOpen) {
        setFiltersModalOpen(false);
      }
      const startDriver = () => {
        const driver = new Driver({
          allowClose: false,
          onReset: () => {
            setWalkthrough(false);
            setSeenNewFeature(googleAnalyticsTag);
          },
        });
        walkthroughDriver(driver);
      };
      startDriver();
    }
  }, [walkthrough]);

  const { data: developmentStatusData } = useQuery(DEVELOPMENT_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultPortfolio.filters.developmentStatuses = data.developmentStatus;
    },
  });
  const { data: resourceThemeData } = useQuery(RESOURCE_THEME_QUERY, {
    onCompleted: (data) => {
      defaultPortfolio.filters.resourceThemes = data.resourceType;
    },
  });
  const { data: shoreStatusData } = useQuery(SHORE_STATUS_QUERY, {
    onCompleted: (data) => {
      defaultPortfolio.filters.shoreStatuses = data.shoreStatus;
    },
  });

  useEffect(() => {
    if (
      resourceThemeData &&
      developmentStatusData &&
      shoreStatusData &&
      portfolioIsEmpty({ portfolios, resourceThemeData, developmentStatusData, shoreStatusData }) &&
      !walkthrough
    ) {
      setFiltersModalOpen(true);
    }
  }, [developmentStatusData, resourceThemeData, shoreStatusData]);

  const addPortfolio = (stagedPortfolios) => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'add_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });
    const newPortfolio = { ...defaultPortfolio };
    setStagingPortfolios([...stagedPortfolios, newPortfolio]);
  };

  const deletePortfolio = (stagedPortfolios, idx) => {
    if (stagedPortfolios.length === 1) {
      setSnack({
        open: true,
        severity: 'error',
        message: 'Must have at least 1 portfolio!',
      });
      return;
    }
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'delete_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });

    const newPortfolios = [
      ...stagedPortfolios.slice(0, idx),
      ...stagedPortfolios.slice(idx + 1, stagedPortfolios.length),
    ];
    setStagingPortfolios(newPortfolios);
  };

  const clearStagingPortfolio = () => {
    setStagingPortfolios([{ ...defaultPortfolio }]);
  };

  const clearPortfolios = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'clear_portfolio',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setPortfolios([{ ...defaultPortfolio }]);
  };

  const openPortfoliosModal = () => {
    gtag('event', ANALYTICS_EVENTS.event, {
      event_category: googleAnalyticsTag,
      event_action: ANALYTICS_EVENT_ACTIONS.buttonClick,
      event_label: 'portfolios_modal',
      userId: getUserId(),
      ...defaultDimensions,
    });
    setStagingPortfolios([...portfolios]);
  };

  const applyBasicFilters = (filters, idx = 0) => {
    const newPortfolio = {
      ...portfolios[idx],
      filters,
    };
    const newPortfolios = [...portfolios];
    newPortfolios[idx] = newPortfolio;
    setPortfolios(newPortfolios);
  };

  // temporary if we add back multiple portfolios
  const loadPortfolio = (portfolios) => {
    setPortfolios(portfolios);
  };

  useEffect(() => {
    portfolios.forEach((p) => {
      const { filters } = p;
      gtag('event', ANALYTICS_EVENTS.event, {
        event_category: googleAnalyticsTag,
        event_action: ANALYTICS_EVENT_ACTIONS.filter,
        event_label: analytics(filters),
        userId: getUserId(),
        ...dimensions(filters, context),
      });
    });
    sessionStorage.setItem(context, JSON.stringify(portfolios));
  }, [portfolios]);

  return (
    <div className={classes.root}>
      <FilterSetsProvider filterSetsCount={portfolios.length}>
        <PortfolioFilters
          portfolios={portfolios}
          applyBasicFilters={applyBasicFilters}
          basicFilterTypes={basicFilterTypes}
          context={context}
          googleAnalyticsTag={googleAnalyticsTag}
          addPortfolio={addPortfolio}
          setPortfolios={setPortfolios}
          defaultFilterTypes={defaultFilterTypes}
          clearStagingPortfolio={clearStagingPortfolio}
          stagingPortfolios={stagingPortfolios}
          deletePortfolio={deletePortfolio}
          filtersModalOpen={filtersModalOpen}
          openPortfoliosModal={openPortfoliosModal}
          developmentStatusData={developmentStatusData}
          resourceThemeData={resourceThemeData}
          shoreStatusData={shoreStatusData}
          open={open}
          setOpen={setOpen}
          clearPortfolios={clearPortfolios}
          loadPortfolio={loadPortfolio}
        />
      </FilterSetsProvider>
      {GraphingComponent && (
        <GraphingComponent
          portfolios={portfolios}
          consolidateAssetsProps={{
            companyIds: portfolios.map((portfolio) => portfolio.filters.companies.map((c) => c.id)),
            assetUnits: portfolios.map((portfolio) => portfolio.filters.assetUnits),
          }}
        />
      )}
    </div>
  );
};
