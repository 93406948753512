import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';

import { BasicFiltersWrapper } from '../BasicFiltersWrapper';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { useFilterSetsContext } from './FilterBarWrapper.context';
import { BasicFilters } from '../Filters';

const useStyles = makeStyles((theme) => ({
  filterBarWrapper: {
    display: 'flex',
    gap: '1.5rem',
    borderBottom: 'solid rgba(0, 0, 0, 0.12) thin',
    padding: '0.5rem 1rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '0.5rem',
      alignItems: 'flex-end',
    },
  },
  filters: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    gap: '1rem',
    width: '100%',
  },
  expandButtonWrapper: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  expandButton: {
    marginTop: '0.5rem',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
  },
  button: { padding: 0 },
  icon: {
    fontSize: '.7rem',
    transform: 'scale(2.5)',
  },
  label: {
    fontSize: '12px',
    lineHeight: '19px',
  },
  controlsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItemClass: {
    minWidth: '1px',
  },
}));

export const FilterBarWrapper = ({
  children,
  filtersJsx,
  filters,
  applyFilters,
  basicFilterTypes,
  context,
  googleAnalyticsTag,
}) => {
  const { currentFilterSetIndex, nextFilterSet, previousFilterSet, filterSetsCount } =
    useFilterSetsContext();

  const [minimize, setMinimize] = useState(false);
  const classes = useStyles();

  const minimizeMaximize = useCallback(() => setMinimize((v) => !v), []);

  return (
    <div className={classes.filterBarWrapper}>
      <div className={classes.filters}>
        <div className={classes.expandButtonWrapper}>
          <Tooltip title={`${minimize ? 'Expand' : 'Collapse'} filter bar`}>
            <IconButton className={classes.expandButton} onClick={minimizeMaximize} size="large">
              {minimize ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            </IconButton>
          </Tooltip>
        </div>
        {filtersJsx
          ? filtersJsx
          : !!filters && (
              <BasicFiltersWrapper minimize={minimize} numberOfFields={basicFilterTypes?.length}>
                <BasicFilters
                  filters={filters}
                  applyFilters={applyFilters}
                  basicFilterTypes={basicFilterTypes}
                  context={context}
                  googleAnalyticsTag={googleAnalyticsTag}
                  filterItemClass={classes.filterItemClass}
                />
              </BasicFiltersWrapper>
            )}
        {filterSetsCount > 1 && (
          <div className={classes.controlsWrapper}>
            <Tooltip title="Move between your filter sets.">
              <div className={classes.controls}>
                <IconButton
                  className={classes.button}
                  onClick={previousFilterSet}
                  disabled={currentFilterSetIndex === 0}
                  size="large"
                >
                  <ExpandLessIcon className={classes.icon} />
                </IconButton>
                <Typography className={classes.label} variant="caption">
                  {currentFilterSetIndex + 1}/{filterSetsCount}
                </Typography>
                <IconButton
                  className={classes.button}
                  onClick={nextFilterSet}
                  disabled={currentFilterSetIndex === filterSetsCount - 1}
                  size="large"
                >
                  <ExpandMoreIcon className={classes.icon} />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
